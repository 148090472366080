
import { Vue, Options } from 'vue-class-component';
import { PatientDocumentService } from '@/services/api';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    item: {
      type: Object,
      required: true
    },

    patientId: {
      type: String,
      required: true
    },

    documentId: {
      type: String,
      required: true
    }
  }
})
export default class DocumentItem extends Vue {
  patientId!: string;
  documentId!: string;
  item!: {
    id: string;
  };

  html = '';
  patientDocumentService = new PatientDocumentService(this.patientId);
  notificationStore = useNotificationStore();

  async mounted() {
    try {
      await this.getBlock();
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }
  }

  async getBlock() {
    this.html = await this.patientDocumentService.getItemHtml(this.documentId, this.item.id);
  }
}
