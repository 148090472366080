
import { Vue, Options } from 'vue-class-component';
import DocumentItem from './DocumentItem.vue';
import { BaseIcon, PageLoading } from '@/lib/components';
import { Document, DocumentTemplate, Review } from '@/models';
import { DocumentTemplateService, PatientDocumentService } from '@/services/api';
import debounce from 'lodash-es/debounce';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },

    review: {
      type: Object,
      required: true
    },

    managementPlanId: {
      type: String,
      required: true
    },

    documentId: {
      type: String,
      required: true
    }
  },
  components: {
    BaseIcon,
    DocumentItem,
    PageLoading
  }
})
export default class Correspondence extends Vue {
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  patientId!: string;
  documentId!: string;
  managementPlanId!: string;
  review!: Review;

  loading = true;
  patientDocumentService = new PatientDocumentService(this.patientId);

  documentTemplateService = new DocumentTemplateService();
  // TODO: Where should we store this?

  documentTemplateId = 'e0ebc918-761c-4484-9ed8-32f73d0f4143';

  documentTemplate: DocumentTemplate | null = null;

  document: Document | null = null;

  content: { [itemId: string]: string } = {};

  get saveDocumentDebounce() {
    return debounce(() => this.saveDocument(), 500);
  }

  mounted() {
    this.load();
  }

  async beforeUnmount() {
    await this.saveDocument();
  }

  async load() {
    this.loading = true;

    try {
      await this.fetchDocumentTemplate();
      await this.fetchOrCreateDocument();
    } catch (e) {
      await this.notificationStore.addErrorNotification({
        title: this.$t('platform.error.fetch-data')
      });
    }

    this.loading = false;
  }

  async fetchOrCreateDocument() {
    if (this.documentId) {
      return await this.fetchDocument(this.documentId);
    }

    if (!this.review.documents) {
      return await this.createDocument();
    }

    const document = this.review.documents.find(
      (doc: { document_template_id: string }) => doc.document_template_id === this.documentTemplateId
    );

    if (document) {
      return await this.fetchDocument(document.id);
    }

    return await this.createDocument();
  }

  async createDocument() {
    const response = await this.patientDocumentService.create({
      document_template_id: this.documentTemplateId,
      review_id: this.review.id,
      status: 'draft',
      dependencies: {
        managementPlan: this.managementPlanId,
        organisationalUnit: this.sessionStore.currentOrganisationId
      }
    });

    this.document = response.data;
    // TODO: Editable fields should request a rendered text template from the server to populate default value
    this.$emit('update:documentId', response.data.id);
  }

  async fetchDocument(documentId: string) {
    this.document = await this.patientDocumentService.fetch(documentId);

    for (const [itemId, value] of Object.entries(this.document.content || {})) {
      this.content = {
        ...this.content,
        [itemId]: value
      };
    }
    // TODO: Any empty editable fields should request a rendered text template from the server to populate default value
    this.$emit('update:documentId', this.document.id);
  }

  async fetchDocumentTemplate() {
    this.documentTemplate = await this.documentTemplateService.fetch(this.documentTemplateId);
  }

  async saveDocument() {
    if (this.documentId) {
      try {
        this.$emit('loading', true);
        await this.patientDocumentService.update(this.documentId, {
          content: this.content
        });
      } catch (e) {
        this.notificationStore.addErrorNotification({
          title: this.$t('custom.uhb.correspondence.save-error')
        });
      } finally {
        this.$emit('loading', false);
      }
    }
  }
}
