import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "p-16" }
const _hoisted_2 = { class: "mb-12" }
const _hoisted_3 = { class: "text-gray-900 font-bold text-2xl" }
const _hoisted_4 = { class: "mt-2 text-gray-500 font-regular text-lg" }
const _hoisted_5 = { class: "bg-white border shadow p-16" }
const _hoisted_6 = {
  key: 0,
  class: "p-10 flex items-center justify-center"
}
const _hoisted_7 = {
  key: 0,
  class: "mb-6 font-semibold text-lg text-gray-900"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = ["onUpdate:modelValue"]
const _hoisted_10 = { class: "mt-16 flex items-center text-sm" }
const _hoisted_11 = { class: "ml-2 text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLoading = _resolveComponent("PageLoading")!
  const _component_DocumentItem = _resolveComponent("DocumentItem")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('custom.uhb.correspondence.patient-letter')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('custom.uhb.correspondence.instructions')), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_PageLoading)
          ]))
        : (_ctx.documentTemplate?.schema?.items)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.documentTemplate.schema.items, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id,
                class: _normalizeClass({ 'mt-12': i > 0 })
              }, [
                (item.type !== 'fixed-patient-info')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (item.heading)
                        ? (_openBlock(), _createElementBlock("h2", _hoisted_7, _toDisplayString(item.heading), 1))
                        : _createCommentVNode("", true),
                      (item.editable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _withDirectives(_createElementVNode("textarea", {
                              "onUpdate:modelValue": ($event: any) => ((_ctx.content[item.id]) = $event),
                              class: "w-full form-textarea",
                              rows: "5",
                              onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.saveDocumentDebounce && _ctx.saveDocumentDebounce(...args)))
                            }, null, 40, _hoisted_9), [
                              [_vModelText, _ctx.content[item.id]]
                            ])
                          ]))
                        : (_openBlock(), _createBlock(_component_DocumentItem, {
                            key: 2,
                            item: item,
                            "document-id": _ctx.document.id,
                            "patient-id": _ctx.patientId
                          }, null, 8, ["item", "document-id", "patient-id"]))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
          : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_BaseIcon, { name: "bpm" }),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('custom.uhb.correspondence.powered-by')), 1)
      ])
    ])
  ]))
}