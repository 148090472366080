import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.html)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          innerHTML: _ctx.html
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("div", _hoisted_2))
  ]))
}